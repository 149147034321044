<template>
  <div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      :opacity="0.50"
      :z-index="2147480000"
    ></loading>
    <div class="popup" v-if="showPopUP">
      <div class="close-popup" v-on:click="closePopUp">Fechar</div>
      <img :src="baseUrl + 'img/popup.jpg'" :alt="app_name + '_popup'" />
    </div>
    <div class="banner-container" v-if="(banners || []).filter(b => b.published).length > 0">
      <carousel2 :per-page="1" autoplay loop :pagination-nabled="false" autoplayTimeout="5000" autoplayHoverPause>
        <slide v-for="banner in (banners || []).filter(b => b.published)" :key="banner.id">
          <template v-if="banner.link">
          <div class='beforeBanner'>
            <a :href="banner.link" target='_blank'>
              <img class="cover" :src="getBannerImageUrl(banner)" :alt="banner.alternate_text" />
            </a>
            </div>
          </template>
          <template v-else>
            <img class="img-block-img" :src="getBannerImageUrl(banner)" :alt="banner.alternate_text" />
          </template>
        </slide>
      </carousel2>
    </div>




    <div v-show='underBanner' class='fx fx-main under-banner wd-100 pd-2-4'>
      <div class='wd-20 fx fx-wrap fx-center green-shadow'>
        <span><i class="fas fa-box-open"></i> <b> Entrega Rápida </b> </span>
        <span> Entregas em Rolândia, Londrina e Região  </span>
      </div>
      <div class='wd-20 fx fx-wrap fx-center green-shadow'>
        <span><i class="fas fa-truck"></i> <b> Entregas para todo o Brasil </b> </span>
        <span> Entregas para todo Brasil via Correios </span>
      </div>
      <div class='wd-20 fx fx-wrap fx-center green-shadow'>
        <span><i class="fas fa-credit-card"></i> <b> Cartão de Crédito </b> </span>
        <span> Pague em até 4x sem Juros </span>
      </div>
      <div class='wd-20 fx fx-wrap fx-center green-shadow'>
        <span><i class="fas fa-arrows-alt-h"></i> <b> Novidades toda Semana </b> </span>
        <span> Confiram o site com as novidades </span>
      </div>
  </div>

  </div>
</template>


<style scoped>
.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(236 226 226 / 59%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.popup img {
  max-width: 70%;
  max-height: 70%;
}
.close-popup {
  font-family: "Open Sans", sans-serif;
  background-color: #f9fbfc;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1em;
}
b {
  font-weight : bold !important;
}

.swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
    -webkit-transition: box-shadow .1s ease-out;
    transition: box-shadow .1s ease-out;
    box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
    -webkit-transition: box-shadow .2s;
    transition: box-shadow .2s;
    border-radius: 4px;
    height: 80px;
    margin-top: -40px;
    top: 50%;
    width: 40px;
}

@media (max-width: 500px) { 
  .swiper-button-next.swiper-button-white,
  .swiper-button-prev.swiper-button-white {
      background : transparent !important;
  }  
}


</style>
<script>
import Banners from "@/services/banners.js";
import Loading from "vue-loading-overlay";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import boolean from "boolean";
import carousel from 'vue-owl-carousel2';
import { Carousel as Carousel2, Slide } from 'vue-carousel';

export default {
  data() {
    return {
      fullBanner: boolean(process.env.VUE_APP_INIT_FULLBANNER),
      underBanner: boolean(process.env.VUE_APP_SHOW_UNDER_BANNERS),
      loadIndex: 0,
      banners: [],
      baseUrl : process.env.BASE_URL, 
      showPopUP: boolean(process.env.VUE_APP_SHOW_POPUP),
      swiperOption: {
        autoplay: {
          enabled: true,
          delay: 5000,
        },
        parallax: true,
        spaceBetween: 30,
        loop: true,
        effect: "fade",
        pagination: {
          type: "fraction",
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  async mounted() {
    this.loadIndex++;
    this.banners = await Banners.list().finally(() => {
      this.loadIndex--;
    });
  },
  methods: {
    closePopUp() {
      this.showPopUP = false
    },
    getBannerImageUrl: function(banner) {
      return Banners.getImageUrl(banner);
    }
  },
  components: {
    Loading,
    carousel,
    Carousel2,
    Slide
  },
  computed: {
    isLoading: function() {
      return this.loadIndex > 0;
    }
  }
};
</script>

<style scoped>
.banner-container {
  position: relative;
  margin-top: 2em;
}
.swiper-pagination {
  display: none;
}
/* template{
  width: 100%!important;
  height: 100%!important;
} */
/*.cover {
     width: 100%;
     object-fit: cover!important;
 }
 */
.beforeBanner{
    width: 90vw;
    margin: 0 auto;
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.beforeBanner a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.beforeBanner img {
    max-width: 80%;
    height: auto;
}

@media (pointer: coarse) {
  .beforeBanner{
    width: 95vw;
    margin: 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.beforeBanner img {
    max-width: 100%;
    height: auto;
}
}
</style>
